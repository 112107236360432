@import '../global/breakpoints.scss';

body {
  color: white;
  font-family: 'monosten', sans-serif;
  height: 100%;
  overflow-x: hidden;
  background: #3E464F;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
*:focus {
  outline: 0;
  
}
.app-wrapper {
  --main-color: #3E464F;
  // &:after {
  //   content: '';
  //   display: block;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   border: 10px solid #222;
  //   box-sizing: border-box;
  //   z-index: 10;
  // }
}
.main-header {
  position: absolute;
  z-index: 10;
}

.content-wrapper {
  position: relative;
  z-index: 10;
  height: 96vh;
  overflow-y: auto;
  @media #{$max-w-small} {
    height: 89vh;
  }
}

.main-wrapper {
  transition: opacity .5s ease;
  .no-animation & {
    transition: opacity 0s ease;
  }
  &.menu-opened {
    opacity: .05;
  }
}

::selection {
  background: #fff;
  color: #333;
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

.wysiwyg-content {
  line-height: 1.4;
}