@font-face {
  font-family: 'Monosten';
  src: url('../assets/fonts/Monosten-C.woff2') format('woff2'),
      url('../assets/fonts/Monosten-C.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Monosten';
  src: url('../assets/fonts/Monosten-E.woff2') format('woff2'),
      url('../assets/fonts/Monosten-E.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/proxima_nova_bold-webfont.woff2') format('woff2'),
       url('../assets/fonts/proxima_nova_bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;

}




@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/proxima_nova_regular_italic-webfont.woff2') format('woff2'),
       url('../assets/fonts/proxima_nova_regular_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;

}




@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/proxima_nova_regular-webfont.woff2') format('woff2'),
       url('../assets/fonts/proxima_nova_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'Oswald';
  src: url('../assets/fonts/Oswald-Regular.woff2') format('woff2'),
      url('../assets/fonts/Oswald-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../assets/fonts/Oswald-ExtraLight.woff2') format('woff2'),
      url('../assets/fonts/Oswald-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../assets/fonts/Oswald-Light.woff2') format('woff2'),
      url('../assets/fonts/Oswald-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../assets/fonts/Oswald-Bold.woff2') format('woff2'),
      url('../assets/fonts/Oswald-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../assets/fonts/Oswald-SemiBold.woff2') format('woff2'),
      url('../assets/fonts/Oswald-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../assets/fonts/Oswald-Medium.woff2') format('woff2'),
      url('../assets/fonts/Oswald-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Droid Sans Japanese';
  src: url('../assets/fonts/DroidSansJapanese.woff2') format('woff2'),
      url('../assets/fonts/DroidSansJapanese.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

