.accordion {
  &__title {
    position: relative;
    cursor: pointer;
    border: 1px solid transparent;

  }
  &__icon {
    
    &:after {
      content: '+';
      display: block;
      font-size: 2.5rem;
      line-height: 50px;
      position: absolute;
      top: 0;
      right: 1rem;
    }
    &.opened {
      &:after {
        content: '-';
      }
    }
  }
  &__content {
    display: none;
    &.visible {
      display: block;
    }
  }
}